:root {
  --swiper-navigation-size: 2.2rem;
}

.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 50%;
  width: calc(2.2rem/44*27);
  width: calc(var(--swiper-navigation-size)/44*27);
  height: 2.2rem;
  height: var(--swiper-navigation-size);
  margin-top: calc(0 - 2.2rem/2);
  margin-top: calc(0 - var(--swiper-navigation-size)/2);
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--swiper-theme-color);
  color: var(--swiper-navigation-color, var(--swiper-theme-color));
}

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  opacity: .35;
  cursor: auto;
  pointer-events: none;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  font-family: swiper-icons;
  font-size: 2.2rem;
  font-size: var(--swiper-navigation-size);
  text-transform: none !important;
  letter-spacing: 0;
  text-transform: none;
  font-feature-settings: normal,;
  font-variant: normal;
  font-variant: initial;
  line-height: 1;
}

.swiper-button-prev,
.swiper-rtl .swiper-button-next {
  left: 0.5rem;
  right: auto;
}

.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
  content: "prev";
}

.swiper-button-next,
.swiper-rtl .swiper-button-prev {
  right: 0.5rem;
  left: auto;
}

.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  content: "next";
}

.swiper-button-lock {
  display: none;
}